<template>
  <div id="app">
    <router-view :path="path" />
    <ToTopButton :path="path" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import ToTopButton from "@/components/Ui/ToTopButton.vue";

export default {
  name: "App",
  data() {
    return {
      // path: String,
      scrollTimer: null,
    };
  },
  components: {
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            // this.scrollY = window.scrollY;
            this.$store.dispatch("setScrollY", window.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            console.log("store scrollY: " + this.$store.getters.scrollY);
          }.bind(this),
          200
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: var(--black);
  line-height: 2.5em;
  font-weight: 700;
  text-align: justify;
  overflow-x: hidden;

  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

:root {
  --main: rgb(76, 82, 112);
  --sub1: rgb(221, 224, 236);
  --sub2: rgb(246, 82, 160);
  --sub3: rgb(250, 202, 226);
  --black: rgb(76, 82, 112);
  --gray: rgb(220, 220, 220);
  --white: rgb(255, 255, 255);
}

html * {
  box-sizing: border-box !important;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1150px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  font-size: 18px;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px !important;
  }
}
//基本文字の設定
h1,
.sub-title {
  font-size: 18px;
  color: var(--main);
  @media screen and (max-width: 767px) {
    line-height: 1.5em;
    text-align: center;
  }
}
h2 {
  font-size: 65px;
  color: var(--sub2);
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.2;
  @media screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 1.3em;
    text-align: center;
    margin-bottom: 10px;
  }
}
h3 {
  font-size: 35px;
  color: var(--main);
  line-height: 1.3;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    font-size: 23px;
  }
}

//共通スペースレンジャー
.pt-150 {
  padding-top: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}
.pt-200 {
  padding-top: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
}
.pb-150 {
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }
}
.pb-200 {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.mt-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
.mt-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}
.mb-150 {
  margin-bottom: 150px;
  @media screen and (max-width: 767px) {
    margin-bottom: 70px;
  }
}
.mb-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.lp-space {
  padding: 150px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }
}

//スマホのときにスペースなしにする
.pb-non {
  @media screen and (max-width: 1024px) {
    padding-bottom: 0;
  }
}
.pt-non {
  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }
}

//PC・SP画像切り替え
.pc-only {
  @media screen and (max-width: 1101px) {
    display: none;
  }
}
.tb-pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tb-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
}
.tb-sp-only {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.flex-cover {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.link-button {
  display: block;
  border: var(--main) 1px solid;
  width: 200px;
  text-align: center;
  color: var(--main);
  margin: 0 auto;
  text-decoration: none;
  transition: 3s;
  &:hover {
    cursor: pointer;
    background-color: var(--main);
    p {
      transition: 1s;
      color: white;
    }
  }
}
</style>
