<template>
  <section class="lp-space">
    <div class="wrap">
      <div class="flex-cover">
        <div class="title">
          <h2 class="title-text">初回無料相談の<br>お申し込みの流れ</h2>
        </div>

        <div class="flow">
          <div v-for="(step, index) in flow" :key="step.index">
            <div class="step" :class="{ 'last-item': index === flow.length - 1 }">
              <h3 class="step-number">{{ step.number }}</h3>
              <div class="step-content">
                <div class="sp-title">
                  <h3 class="step-number">{{ step.number }}</h3>
                  <h4 v-html="step.title"></h4>
                </div>
                <p>
                  {{ step.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FlowVue",
  data() {
    return {
      flow: [
        {
          number: "STEP 1",
          title: "当サイトの<br class='sp-only'>お問い合わせフォームから<br class='sp-only'>お申し込み",
          content: "当サイトの下部にあるお問い合わせフォームから、必要な情報を入力してお申し込みをします。",
        },
        {
          number: "STEP 2",
          title: "初回無料相談の日程調整",
          content: "初回無料相談の日程調整について、当社からメールでご連絡をしますので、日程の調整をします。なお、初回無料相談の方法は、①zoom、②当社にて、③貴社にて（岐阜駅から半径10キロ以内に限る）のいずれかで調整となります。ご希望の方法を承りますが、当社が決定します。",
        },
        {
          number: "STEP 3",
          title: "初回無料相談",
          content: "調整した日時に初回無料相談を実施します。初回無料相談は最大1時間です。当社の求人ノウハウについてご説明させていただき、実行する施策をご提案させていただきます（ご提案が後日になる場合もあります）。",
        },
        {
          number: "STEP 4",
          title: "求人改善の実施",
          content: "初回無料相談後、当社に求人支援のお仕事をご依頼するとなった場合は、求人改善の業務を実行していきます。実行する内容は、クライアントの状況・お悩みをもとにクライアント個別での提案となります。",
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    .wrap {
      .flex-cover {
        @media screen and (max-width: 1100px) {
          display: block;
        }
        .title {
          width: 35%;
          margin-bottom: auto;
          @media screen and (max-width: 1100px) {
            width: 100%;
            margin-bottom: 10px;
          }
          .title-text {
            font-size: 32px;
            letter-spacing: 0.5rem;
            line-height: 3.5rem;
            margin: 0;
            color: var(--main);
            @media screen and (max-width: 1100px) {
              text-align: center;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              font-size: 25px;
              line-height: 2.5rem;
            }
          }
        }
        .flow {
          width: 58%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 85%;
            margin: auto;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding-top: 20px;
          }
          .step {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 80px;
            @media screen and (max-width: 1100px) {
              margin-bottom: 20px;
            }
            @media screen and (max-width: 767px) {
              margin-bottom: 30px;
            }
            .step-number {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20%;
              font-size: 22px;
              border-radius: 15px;
              color: var(--white);
              background-color: var(--main);
              padding: 15px 0;
              margin-bottom: auto;
              @media screen and (max-width: 1100px) {
                display: none;
              }
            }
            .step-content {
              width: 75%;
              @media screen and (max-width: 1100px) {
                width: 100%;
              }
              .sp-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                @media screen and (max-width: 1100px) {
                  margin-bottom: 10px;
                }
                @media screen and (max-width: 767px) {
                  display: block;
                }
                .step-number {
                  display: none;
                  @media screen and (max-width: 1100px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-size: 22px;
                    border-radius: 15px;
                    color: var(--white);
                    background-color: var(--main);
                    padding: 15px 0;
                    margin-top: auto;
                    margin-right: 15px;
                    margin-bottom: auto;
                  }
                  @media screen and (min-width: 768px) and (max-width: 1100px) {
                    width: 20%;
                  }
                }
                h4 {
                  width: 100%;
                  font-size: 28px;
                  line-height: 2.5rem;
                  letter-spacing: 0.3rem;
                  margin: 0;
                  color: var(--sub2);
                  @media screen and (min-width: 768px) and (max-width: 1100px) {
                    width: 75%;
                    font-size: 24px;
                    line-height: 2rem;
                  }
                  @media screen and (max-width: 767px) {
                    font-size: 24px;
                    line-height: 2rem;
                    letter-spacing: 0rem;
                    font-weight: 600;
                    text-align: center;
                    margin: 15px 0;
                  }
                }
              }
              h4 {
                font-size: 28px;
                margin-bottom: 10px;
              }
              p {
                line-height: 2rem;
                margin-bottom: 10px;
              }
              img {
                margin-top: 20px;
              }
            }
          }
          .last-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>