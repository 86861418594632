// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl: 'https://u-create21.com/',
    scrollY: 0,
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
    scrollY(state) {
      return state.scrollY
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
    setScrollY(state, payload) {
      state.scrollY = payload.scrollY;
    },
  },
  actions: {
    setRootUrl({
      commit
    }) {
      let rt = process.env.NODE_ENV === 'production' ? "https://u-create21.com" : "http://u-create21.com.localhost";
      //↑同じ意味↓
      // let rt;
      // if(process.env.NODE_ENV === 'production') {
      //   rt = "https://aru-dry.com";
      // }else {
      //   rt = "http://aru-dry.localhost";
      // }
      commit('setRootUrl', rt);
    },
    setScrollY({
      commit
    }, scrollY) {
      let payload = {
        scrollY: scrollY
      };
      commit('setScrollY', payload);
    },
  },
  modules: {
  },
  plugins: [createPersistedState()],
  
})

