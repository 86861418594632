<template>
  <section class="lp-space">
    <div class="wrap">
      <h2 id="contact" class="contact-title">初回無料相談のお申し込み</h2>
      
      <div class="contact-form">
        <p class="des" v-if="pageType === 1">
          ご入力した内容を確認して、よろしければ「送信」してください。
        </p>

        <div class="cover">
          <div class="form" v-if="pageType === 0 || pageType === 1">
            <div class="input-cover">
              <p class="sub">
                <span>
                  法人名
                  <br class="sp-only">
                  <span class="message">(※個人事業主のお申し込みは不可)</span>
                </span>
                <span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.companyName"
                  type="text"
                  class="row1 cv-company"
                  placeholder="例）株式会社 山田商会"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.companyName }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">
                担当者の役職名
                <span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.position"
                  type="text"
                  class="row1 cv-position"
                  placeholder="例）取締役"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.position }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">担当者名<span class="hissu">必須</span></p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.name"
                  type="text"
                  class="row1 cv-name"
                  placeholder="例）山田 花子"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.name }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">
                返信先メールアドレス<span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.mail"
                  type="email"
                  class="row1 cv-email"
                  placeholder="例）sample@gmail.com"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.mail }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">
                電話番号<span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.tel"
                  type="text"
                  class="row1 cv-tel"
                  placeholder="例）0123456789"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.tel }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">
                <span>
                  ホームページURL
                  <br class="sp-only">
                  <span class="message">(ない場合は「なし」とご記入ください)</span>
                </span>
                <span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.url"
                  type="text"
                  class="row1 cv-url"
                  placeholder="例）https://u-create21.com"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.url }}
              </div>
            </div>

            <div class="input-cover">
              <p class="sub">
                <span>
                  採用を強化したい職種
                  <br class="sp-only">
                  <span class="message">(複数記入可)</span>
                </span>
                <span class="hissu">必須</span>
              </p>
              <div v-if="pageType === 0" class="row-cover">
                <input
                  v-model="form.occupation"
                  type="text"
                  class="row1 cv-occupation"
                  placeholder="例）事務、ドライバー、営業"
                />
              </div>
              <div v-else-if="pageType === 1" class="confirmation">
                {{ form.occupation }}
              </div>
            </div>

            <div class="input-cover last">
              <p class="sub">お問い合わせ内容<span class="hissu">必須</span></p>
              <div v-if="pageType === 0">
                <textarea
                  v-model="form.description"
                  class="cv-description"
                  placeholder="こちらにお問い合わせ内容を入力してください。"
                ></textarea>
              </div>
              <textarea
                v-else-if="pageType === 1"
                v-model="form.description"
                class="confirmation"
                readonly
              ></textarea>
            </div>
          </div>
          <div v-if="pageType === 0">
            <p class="kakunin">
              記入が終わりましたら、下の「確認」を押し、ご記入内容をご確認ください。
            </p>
          </div>

          <div @click="confirm" class="button" v-if="pageType === 0">
            <!-- <div class="button" v-if="pageType === 0"> -->
            <div><p>確認</p></div>
          </div>

          <div class="soushin" v-if="pageType === 1">
            <div @click="back" class="button"><p>修正</p></div>
            <div @click="send" class="button">
              <p>送信</p>
            </div>
            <!-- @click="send"を本番は復活させること -->
          </div>
          <div v-else-if="pageType === 2" class="complete">
            <h3>送信完了しました</h3>
            <p>ご記入いただいた情報を送信しました。</p>
            <p>
              確認のため、お客様へ自動返信メールをお送りしております。<br />自動返信メールが届かない場合は、迷惑メールボックスの中をご確認ください。
            </p>
            <!-- <router-link to="/"> -->
            <div class="button2" @click="top">トップに戻る</div>

            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import formValidation from "../Library/lpFormValidation";

export default {
  name: "LpContact",
  components: {},
  props: {
    // pageType: Number,
  },
  data() {
    return {
      pageType: 0,
      // checked: false,
      form: {
        companyName: "",
        position: "",
        name: "",
        mail: "",
        tel: "",
        url: "",
        occupation: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  mounted() {
    // フォーカスアウトイベントを追加
    formValidation.createValidateEvent();
  },
  methods: {
    confirm() {
      // 全項目の一斉チェック（戻り値のtrue or falseで判断できる）
      const checkResult = formValidation.checkAll();
      console.log(checkResult);
      
      if (checkResult === true) {
        this.pageType = 1;
        this.$scrollTo("#mailto", 500, { easing: "ease" });
        if (this.form.type === "9") {
          this.checked = true;
        } else {
          this.checked = false;
        }
        return;
      } else {
        return false;
      }
    },
    send() {
      let params = new URLSearchParams();
      // パラメーター追加
      params.append("company_name", this.form.companyName);
      params.append("position", this.form.position);
      params.append("name", this.form.name);
      params.append("mail", this.form.mail);
      params.append("tel", this.form.tel);
      params.append("url", this.form.url);
      params.append("occupation", this.form.occupation);
      params.append("description", this.form.description);
      
      this.$axios
        .post(`${this.rootUrl}/api/lp-send.php`, params)
        // .post(`http://u-create21.com.localhost/api/lp-send.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            this.$scrollTo("#mailto", 500, { easing: "ease" });
            return;
          } else {
            alert(response.data.message);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
      this.$scrollTo("#mailto", 500, { easing: "ease" });

      // DOMの更新を待ってからeventを設置する
      this.$nextTick(function () {
        formValidation.createValidateEvent();
      });
    },
    top() {
      location.reload();
      this.$scrollTo("#app", 500, { easing: "ease" });
      this.pageType = 0;
      this.form = {
        companyName: "",
        position: "",
        name: "",
        mail: "",
        tel: "",
        url: "",
        occupation: "",
        description: "",
      };
      this.checked = false;
    },
  },
}
</script>

<style lang="scss" scoped>
  section {
    .wrap {
      @media screen and (max-width: 767px) {
        padding-right: 10px;
        padding-left: 10px;
      }
      .contact-title {
        font-size: 32px;
        letter-spacing: 0.5rem;
        line-height: 3.5rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 20px;
        color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 22px;
          letter-spacing: 0.4rem;
        }
      }

      .contact-form {
        .des{
          // background-color: none;
          padding-bottom: 50px;
          text-align: center;
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
            padding: 0px 20px ;
          }
        }
        .cover {
          background-color: var(--gray);
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding: 30px 100px;
          @media screen and (min-width: 768px) and (max-width: 1150px) {
            padding: 30px 70px;
          }
          @media screen and (max-width: 767px) {
            padding-right: 20px;
            padding-left: 20px;
            padding-top: 10px;
            padding-bottom: 20px;
          }

          p {
            line-height: 1.6em;
          }

          .title {
            padding-bottom: 30px;
            padding-left: 0;

            @media screen and (max-width: 767px) {
              padding-bottom: 5px;
            }

            h3 {
              padding-top: 15px;
              @media screen and (max-width: 767px) {
                padding-top: 30px;
              }
            }
          }

          .form {
            margin-top: 30px;
            .input-cover {
              text-align: left;
              margin-bottom: 30px;
              @media screen and (max-width: 767px) {
                margin-bottom: 15px;
              }
              .sub {
                font-size: 18px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .message {
                  @media screen and (max-width: 767px) {
                    display: block;
                    font-size: 12px;
                    line-height: 15px;
                  }
                }
                .hissu {
                  white-space: nowrap;
                  padding: 5px 20px;
                  color: var(--white);
                  background-color: var(--sub2);
                  @media screen and (max-width: 767px) {
                    padding: 5px 10px;
                    margin-left: 5px;
                  }
                }
              }
              input,
              textarea {
                border: none;
                box-sizing: border-box;
              }
              .row-cover,
              .confirmation {
                color: var(--black);
                border: 1px solid #a7a5a4;
                background-color: var(--white);
              }
              .select {
                display: inline-block;
                position: relative;
                border: 1px solid #eee;
                // width: 100%;
                // height: 70px;
                vertical-align: middle;
              }
              .select::before {
                position: absolute;
                top: 22px;
                right: 16px;
                width: 0;
                height: 0;
                border-width: 15px 10px 0 10px;
                border-style: solid;
                border-color: var(--main) transparent transparent transparent;
                content: "";
                pointer-events: none;
              }
              select {
                appearance: none;
                min-width: 300px;
                padding: 20px 50px 20px 30px;
                // padding-right: 1em;
                border: 1px solid #a7a5a4;
                outline: 0;
                background: #fff;
                background-image: none;
                box-shadow: none;
                text-indent: 0.01px;
                text-overflow: ellipsis; // 文字の省略
                cursor: pointer;
                font-size: 18px;
                @media screen and (max-width: 767px) {
                  min-width: 200px;
                          padding: 20px;
                          width: 100%;
                }
              }

              .row1,
              textarea {
                width: 100%;
                padding: 20px 10px;
                font-size: 20px;
                resize: auto;
                cursor: text;
                white-space: pre-wrap;
                overflow-wrap: break-word;
                column-count: initial !important;
                word-break: break-all;
                @media screen and (max-width: 767px) {
                  font-size: 16px;
                  padding: 15px 10px;
                }
              }

              textarea {
                border: 1px solid #a7a5a4;
                height: 300px;
                text-rendering: auto;

                @media screen and (max-width: 767px) {
                  margin: 0;
                }
              }

              .sp {
                &:nth-child(n + 2) {
                  margin-left: 20px;
                  @media screen and (max-width: 767px) {
                    margin-left: 0;
                  }
                }

                @media screen and (max-width: 767px) {
                  display: block;
                }
              }
            }
            .last {
              margin-bottom: 5px;
            }
          }
          .button,
          .button2 {
            border: 1px solid var(--main);
            color: var(--main);
            padding: 20px 0;
            width: 200px;
            text-align: center;
            margin: 50px auto;
            cursor: pointer;
            transition: 0.8s;
            p {
              font-size: 20px;
              @media screen and (max-width: 767px) {
                font-size: 18px;
              }
            }

            @media screen and (max-width: 767px) {
              width: 200px;
              padding: 10px 0px;
              margin: 30px auto;
            }

            &:hover {
              color: white !important;
              background-color: var(--main);
              transition: 0.8s;
            }
          }

          .soushin {
            display: flex;
            justify-content: space-between;
            .button,
            .button2 {
              width: 150px;
              @media screen and (max-width: 767px) {
              }
              width: 40%;
              font-size: 18px;
              padding: 10px 0;
            }
          }

          .complete {
            text-align: left;
            h3 {
              margin-bottom: 50px;
              text-align: center;
              color: var(--main);
              font-size: 28px;
              @media screen and (max-width: 767px) {
              padding-top: 30px;
              }
            }
            p{
              font-size: 16px;
              text-align: center;
              @media screen and (max-width: 767px) {
                text-align: start;
              }
            }
            a {
              text-decoration: none;
              color: var(--sub);

              .button2 {
                width: 170px;
                margin-top: 70px;
                margin-bottom: 50px;
              }
              &:hover{
                color: var(--main);
              }
            }
            .contact-no,.contact-mail{
              // cursor: none;
              font-size: 28px;
              text-align: center;
            }
            .contact-no{
              padding-top:50px;
            }
            .contact-mail{
              margin-top:20px;
              padding-bottom:30px;
              @media screen and (max-width: 767px){
                font-size: 22px;
              }
            }

          }
        }

        .confirmation {
          padding: 20px 60px 20px 20px;
          @media screen and (max-width: 767px){
            padding: 10px;
          }
        }
        .non-border {
          border: none !important;
          p {
            margin-bottom: 50px;
          }
          .title {
            font-size: 20px;
            color: var(--main);
            margin-left: -10px;
          }
        }

        .click-ok {
          cursor: pointer !important;
        }
        .click-ng {
          pointer-events: none;
          cursor: not-allowed !important;
          background-color: gray;
        }
        .desc {
          color: var(--sub3);
        }
        .ECM_CheckboxInput {
          padding: 12px 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          font-size: 16px;
          @media screen and (max-width: 767px) {
            // flex-direction: column;
            align-items: flex-start;
            padding: 5px 0;
          }
        }
        .ECM_CheckboxInput-Input {
          margin: 0;
          width: 0;
          opacity: 0;
        }
        .ECM_CheckboxInput:hover > .ECM_CheckboxInput-DummyInput {
          transform: scale(1.1);
        }
        .ECM_CheckboxInput-Input:focus + .ECM_CheckboxInput-DummyInput {
          transform: scale(1.1);
        }
        .ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput {
          background: var(--main);
          border: none;
        }
        .ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput::before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 35%;
          height: 4px;
          border-radius: 2px;
          transform: translate(-6px, 5px) rotateZ(-135deg);
          transform-origin: 2px 2px;
          background: #ffffff;
        }
        .ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70%;
          height: 4px;
          border-radius: 2px;
          transform: translate(-6px, 5px) rotateZ(-45deg);
          transform-origin: 2px 2px;
          background: #ffffff;
        }
        .ECM_CheckboxInput-DummyInput {
          position: relative;
          top: 0;
          left: 0;
          display: block;
          width: 32px;
          height: 32px;
          border: solid 2px transparent;
          // background: rgba(0, 0, 0, .15);
          border: 2px solid var(--main);
          // border-radius: 50%;
          transition: all 0.15s linear;
          @media screen and (max-width: 767px) {
            // width: 74px;
            // margin-top: 5px;
          }
        }
        .ECM_CheckboxInput-LabelText {
          margin-left: 12px;
          display: block;
          font-size: 18px;
          font-weight: bold;
        }

        @media (min-width: 751px) {
          a[href^="tel:"] {
            pointer-events: none;
            cursor: default;
          text-decoration: none;
          }
        }
        .kakunin{
          font-size: 16px;
          text-align: center;
          @media screen and (max-width: 767px){
            text-align: left;
          }
        }
      }
    }
  }
</style>