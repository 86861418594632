<template>
  <div>
    <Header />

    <div id="fv" class="fv">
      <div class="fv-img">
        <img 
          class="tb-pc-only"
          src="@/assets/lp/firstview.webp" 
          alt="【株式会社U-CREATE】SNS、知名度、リスト不要の求人支援サービス"
        >
        <img 
          class="sp-only"
          src="@/assets/lp/firstview-sp.webp" 
          alt="【株式会社U-CREATE】SNS、知名度、リスト不要の求人支援サービス"
        >
      </div>
      <h1 class="fv-title">【当サイト限定】<br class="sp-only">初回無料相談実施中!</h1>
    </div>

    <IssueVue />
    <ResultVue />
    <PointMessageVue />
    <RepresentativeVue />
    <ReasonsVue />
    <FlowVue />
    <PointMessageVue />
    <ContactVue />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Layout/LpHeader.vue';
import IssueVue from '@/components/RecruitLp/Issue.vue';
import ResultVue from '@/components/RecruitLp/Result.vue';
import PointMessageVue from '@/components/RecruitLp/PointMessage.vue';
import RepresentativeVue from '@/components/RecruitLp/Representative.vue';
import ReasonsVue from '@/components/RecruitLp/Reasons.vue';
import FlowVue from '@/components/RecruitLp/Flow.vue';
import ContactVue from '@/components/RecruitLp/Contact.vue';
import Footer from '@/components/Layout/LpFooter.vue';

export default {
  name: "RecruitLp",
  components: {
    Header,
    IssueVue,
    ResultVue,
    PointMessageVue,
    RepresentativeVue,
    ReasonsVue,
    FlowVue,
    ContactVue,
    Footer,
  },
  mounted() {
    this.setHeight();
  },
  methods: {
    setHeight() {
      var height = window.innerHeight;
      document.getElementById('fv').style.height = height + 'px';
    },
  }
}
</script>

<style lang="scss" scoped>
  * {
    @media screen and (max-width: 767px) {
      font-weight: 500;
    }
  }

  .fv {
    height: 100vh;
    background-color: var(--main);
    @media screen and (max-width: 1100px) {
      height: auto;
      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
      }
    }
    .fv-img {
      position: relative;
      height: 90%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        height: 84vh;
      }
      @media screen and (max-width: 767px) {
        height: 84%;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    .fv-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10%;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0.5rem;
      color: var(--white);
      background-color: var(--main);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        height: 13vh;
        font-size: 32px;
      }
      @media screen and (max-width: 767px) {
        height: 16%;
        font-size: 26px;
        line-height: 35px;
      }
    }
  }
</style>