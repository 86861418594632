var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button"},[(_vm.path !== '/closed/recruit/')?_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: 0,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: 0,\n    }"}],staticClass:"to-top-button",class:{ 'over-baseline': _vm.scrollY >= 500 }},[_vm._m(0)]):_vm._e(),(_vm.path === '/closed/recruit/')?_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: 0,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: 0,\n    }"}],staticClass:"lp-to-top-button",class:{ 'over-baseline': _vm.scrollY >= 300 }},[_c('div',{staticClass:"top",class:{ 'lp-btn': _vm.path == '/closed/recruit/' }},[_c('img',{attrs:{"src":require("@/assets/lp/to_top.webp")}})])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("@/assets/GH-syoukai-top-pagetop.png")}})])
}]

export { render, staticRenderFns }