<template>
  <section>
    <div class="flex-cover wrap">
      <div class="left">
        <h2>Company<br>Information</h2>
        <p>会社概要</p>
      </div>
      <div class="right">
        <div class="creator-img">
          <img class="creator-icon" src="@/assets/u_create_creator_01.webp" alt="株式会社U-CREATE 代表取締役 酒井優">
          <p>
            株式会社U-CREATE
            <br>
            代表取締役 酒井 優
          </p>
        </div>
        <UiTable :table-list="tableList" />
      </div>
    </div>
  </section>
</template>

<script>
import UiTable from "@/components/Ui/Table.vue";

export default {
  name: "CreatorProfile",
  components: {
    UiTable,
  },
  data() {
    return {
      tableList:[
        { id: 1, th: "会社名", td: "株式会社U-CREATE" },
        { id: 2, th: "代表取締役", td: "酒井 優" },
        { id: 3, th: "所在地", td: "岐阜県岐阜市加納桜田町2-5-2" },
        { id: 4, th: "営業時間", td: "平日9時～18時<br class='sp-only'>（土日祝日、年末年始はお休み）" },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  .flex-cover {
    padding: 150px 0;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      display: block;
      padding: 75px 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 75px 20px;
    }
    .left {
      width: 48%;
      @media screen and (max-width: 1100px) {
        width: 100%;
        margin-bottom: 20px;
      }
      h2 {
        color: var(--black);
        font-size: 70px;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          text-align: center;
        }
        @media screen and (max-width: 767px) {
          font-size: 60px;
          line-height: 3.5rem;
        }
      }
      p {
        color: var(--black);
        @media screen and (max-width: 1100px) {
          text-align: center;
        }
      }
    }
    .right {
      width: 48%;
      @media screen and (max-width: 1100px) {
        width: 100%;
        padding-top: 20px;
      }
      .creator-img {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .creator-icon {
          width: 30%;
          aspect-ratio: 1/1;
          border-radius: 20px;
          @media screen and (max-width: 1100px) {
            width: 35%;
          }
        }
        p {
          display: flex;
          align-items: flex-end;
          width: 65%;
          line-height: 1.5rem;
          margin: 0;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 24px;
            line-height: 2rem;
            margin-left: 20px;
          }
          @media screen and (max-width: 767px) {
            font-size: 16px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>