<template>
  <section class="lp-space">
    <div class="wrap">
      <div class="flex-cover">
        <div class="title">
          <img src="@/assets/lp/t003.webp" alt="POINT！" class="title-img tb-pc-only">
          <img src="@/assets/lp/t003sp.webp" alt="POINT！" class="title-img sp-only">
        </div>

        <div class="reasons">
          <img class="graph-img" src="@/assets/lp/graph.webp" alt="総人口の推移">
          <div v-for="(reason, index) in reasons" :key="reason.index">
            <div class="reason" :class="{ 'last-item': index === reasons.length - 1 }">
              <h3 class="reason-title" v-html="reason.title"></h3>
              <p v-html="reason.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReasonsVue",
  data() {
    return {
      reasons: [
        {
          title: "経営者視点でのノウハウだから",
          content: "経営者の方は、有料求人媒体の営業マンから電話営業などを日々受けていると思います。こういった特定のサービスの営業マンは、求人についていろいろと情報を教えてくれますが、それらは自社のサービスを販売するためのポジショントークが入った情報になってしまっています。これはビジネスにおいて仕方がないことです。しかし、このような情報は、求人を強化したい経営者にとっては無益なことです。当社のサービスは、特定の有料求人媒体の利用へ誘導するものではなく、経営者視点で本当に求人に効果がある施策を実行するものになっています。"
        },
        {
          title: "集客 × ウェブ制作が専門だから",
          content: "現在の求職者の求人活動は、ウェブ上を主として行われます。そのため、求人の教科には、集客とウェブ制作のスキルが必要になります。当社は集客やマーケティング、ウェブ制作を専門としているので、現在の求職者の行動パターンにミートした求人の施策を提供できます。実際のところ、多くの企業は「求職者を集客すること」に悩んでいます。多くの求人系サービスの提供者が集客やウェブ制作を専門していない中、集客とウェブ制作に強い求人の施策・ノウハウになっているため、結果を出すことができます。"
        },
        {
          title: "一度作れば半永久的に永続する<br>求人の仕組みを構築するから",
          content: "当社の求人強化ノウハウは、一度作れば半永久的に永続する求人の仕組みを構築するものになっています。有料求人媒体のようにお金を払い続けないと継続できないものではなく、中長期的にコストパフォーマンス良く運用できる仕組みを構築します。上記の実績のとおり、有料求人媒体を使用せず、求職者の応募を獲得ができる仕組みをまずは目指します。このようにコスト面も考慮したノウハウ・施策のため、多くの企業にとって実行できる、継続できるものとなっています。"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    background-color: var(--sub3);
    .wrap {
      .flex-cover {
        .title {
          width: 35%;
          margin-bottom: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 10px;
          }
          .title-img {
            width: 80%;
            @media screen and (max-width: 1100px) {
              width: 100%;
            }
          }
        }
        .reasons {
          width: 58%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .graph-img {
            margin-bottom: 60px;
            @media screen and (max-width: 767px) {
              margin-bottom: 40px;
            }
          }
          .reason {
            margin-bottom: 40px;
            .reason-title {
              color: var(--sub2);
              font-size: 28px;
              line-height: 2.5rem;
              letter-spacing: 0.3rem;
              margin-top: 0;
              margin-bottom: 10px;
              @media screen and (max-width: 767px) {
                letter-spacing: 0rem;
                font-size: 22px;
                font-weight: 600;
                line-height: 2rem;
              }
            }
            p {
              line-height: 2rem;
              margin: 0;
            }
          }
          .last-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>