<template>
  <div class="home-contact wrap">
    <h2 id="mailto">Contact</h2>
    <p class="sub-title">お仕事のご依頼・パートナー<br class="sp-only">に関するお問い合わせ</p>

    <h3 class="mail-contact">メールでのお問い合わせ</h3>

    <HomeContactContactForm
      class="contact-form"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    HomeContactContactForm,
  },
  props: {},
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-contact {
  padding: 150px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 10vh 0;
  }
  @media screen and (max-width: 767px) {
    padding: 75px 0 20px 0;
  }
  #mailto {
    color: var(--black);
    font-size: 70px;
    font-weight: 700;
    line-height: 4rem;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 60px;
      line-height: 3.5rem;
      margin-bottom: 20px;
    }
  }
  .sub-title {
    text-align: center;
  }
  .mail-contact {
    font-size: 28px;
    line-height: 5rem;
    text-align: center;
    margin-top: 30px;
    color: var(--white);
    background-color: var(--black);
  }
  .contact-form{
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
</style>