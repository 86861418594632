<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline': (scrollY >= 1000 && path === '/') || (scrollY >= 0 && path !== '/'),
      }"
    >
      <div class="header-cover">
        <div class="header-left">
          <div class="to-top" @click="top">
            <img src="@/assets/logo001.webp" />
          </div>
        </div>

        <div class="header-right">
          <!-- <div class="tel" v-scroll-to="'#contact-tel'">
            <p>058-214-9005</p>
            <span>(受付時間 平日9:00～18:00)</span>
          </div> -->
          <div class="mail-contact">
            <router-link to="/" v-scroll-to="`#mailto`"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="50px"
                height="50px"
                viewBox="0 0 25 25"
                version="1.1"
              >
                <g id="surface1">
                  <path
                    style="
                      stroke: none;
                      fill-rule: nonzero;
                      fill: var(--main);
                      fill-opacity: 1;
                    "
                    d="M 12.5 24.183594 C 6.058594 24.183594 0.816406 18.941406 0.816406 12.5 C 0.816406 6.058594 6.058594 0.816406 12.5 0.816406 C 18.941406 0.816406 24.183594 6.058594 24.183594 12.5 C 24.183594 18.941406 18.941406 24.183594 12.5 24.183594 Z M 12.5 1.566406 C 6.46875 1.566406 1.566406 6.46875 1.566406 12.5 C 1.566406 18.53125 6.46875 23.433594 12.5 23.433594 C 18.53125 23.433594 23.433594 18.53125 23.433594 12.5 C 23.433594 6.46875 18.53125 1.566406 12.5 1.566406 Z M 12.5 1.566406 "
                  />
                  <path
                    style="
                      stroke: none;
                      fill-rule: nonzero;
                      fill: var(--main);
                      fill-opacity: 1;
                    "
                    d="M 5.855469 8.316406 L 5.855469 17.648438 L 19.148438 17.648438 L 19.148438 8.316406 Z M 17.730469 9.066406 L 12.5 13.378906 L 7.269531 9.066406 Z M 6.605469 16.898438 L 6.605469 9.484375 L 12.5 14.355469 L 18.394531 9.488281 L 18.394531 16.898438 Z M 6.605469 16.898438 "
                  />
                </g>
              </svg>
              <span style="font-size: 20px;">mail</span>
            </router-link>
          </div>

          <!-- <div class="mail-contact" v-scroll-to="'#contact-line'">
            <img alt="ライン" src="@/assets/Line.png" class="line-img" />
            <span>LINE</span>
          </div> -->
          <!-- <div class="hamburger">
            <UiDrawer />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    // UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      //  documentheight:0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);//長さはかるコンソール
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--main);
}
.st1 {
  fill: none;
}

.header {
  background-color: rgba(255, 255, 255, 0);
  width: 100vw;
  height: 70px;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  top: 0;
  left: 0;
  // display: none;
  @media screen and ( max-width: 767px){
    height: 60px;
  }

  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2%;
    margin-left: 2%;
    width: 100%;
    margin-bottom: 0;
  @media screen and ( max-width: 767px){
    margin-right: 20px;
    margin-left: 20px;
  }
  }
  .header-left {
    width: 300px;
    // margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;

    .to-top {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.5s;
      img {
        width: 85%;
      }
    }
    .to-top:hover {
      opacity: 0.9;
    }
  }

  .header-right {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1480px) {
      margin-right: 1%;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      padding-right: 10px;
    }

    .mail-contact {
      width: 100%;
    }

    a {
      text-decoration: none;
      color: var(--main);
    }

    .tel {
      color: var(--main);
      display: flex;
      align-items: center;
      @media screen and (min-width: 801px) and (max-width: 1010px) {
        flex-direction: column;
        line-height: 1.3em;
        margin-left: 18%;
      }
      @media screen and (min-width: 768px) and (max-width: 800px) {
        flex-direction: column;
        line-height: 1.3em;
        margin-left: 10%;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
      p {
        font-size: 26px;
        letter-spacing: 0.15em;
        transition: 0.5s;
      }
      span {
        font-size: 16px;
      }
    }
    .tel:hover {
      cursor: pointer;
      transition: 0.5s;
      p {
        color: var(--sub);
        letter-spacing: 0.35rem;
        transition: 0.5s;
      }
      span {
        transition: 0.5s;
      }
    }

    .mail-contact,
    .mail-contact2 {
      display: inline-block;
      transition: 0.3s;
      // -webkit-transform: scale(1);
      // transform: scale(1);
      animation: poyo 2s linear 2s infinite forwards;

      img,
      svg,
      span {
        vertical-align: middle;
      }
      .line-img {
        width: 40px;
        height: 40px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
      span {
        margin-left: 10px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .mail-contact {
      // opacity:0;
      animation: poyo 2s linear 1s infinite forwards;
    }
    .mail-contact2 {
      // opacity:0;
      animation: poyo 2s linear 1s infinite forwards;
    }

    .mail-contact:hover {
      letter-spacing: 0.3rem;
      transition: 0.5s;
      animation-play-state: paused;
      span {
        color: var(--sub);
      }
      // -webkit-transform: scale(1.1);
      // transform: scale(1.1);
      //  animation: poyo 2s linear .5s infinite forwards;
    }
    @keyframes poyo {
      from,
      to {
        transform: none;
      }
      0% {
        transform: scale(1);
      }
      30% {
        transform: scale(1);
      }
      40% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(1);
      }
      60% {
        transform: scale(1.1);
      }
      70% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
.over-baseline {
  display: initial !important;
  display: flex !important;
  transition: 2s;
  background-color: rgba(255, 255, 255, 0.2);

  .header-cover {
    border: none;
  }
}
</style>
