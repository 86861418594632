<template>
  <section>
    <div class="flex-cover wrap">
      <div class="left">
        <h2>Business<br>Lines</h2>
        <p>事業内容</p>
      </div>
      <div class="right">
        <div class="business-line">
          <h3 class="line-number">01</h3>
          <div class="business-line-content">
            <div class="sp-title">
              <h3 class="line-number">01</h3>
              <h4>Webマーケティング</h4>
            </div>
            <p>・マーケティングスキーム提案</p>
            <p>・SNS、SEO、MEO支援</p>
            <p>・広告運用代行</p>
            <p>・市場と競合の調査と分析</p>
            <img src="@/assets/u_create_top_004.webp" alt="株式会社U-CREATEのホームページで売上・集客アップ！">
          </div>
        </div>
        <div class="business-line">
          <h3 class="line-number">02</h3>
          <div class="business-line-content">
            <div class="sp-title">
              <h3 class="line-number">02</h3>
              <h4>WEB制作・システム開発</h4>
            </div>
            <p>・ホームページ制作</p>
            <p>・システム開発</p>
            <p>・ECサイト/ECモール制作</p>
            <img src="@/assets/u_create_top_001.webp" alt="株式会社U-CREATEの求人サイトで求人強化！">
          </div>
        </div>
        <div class="business-line">
          <h3 class="line-number">03</h3>
          <div class="business-line-content">
            <div class="sp-title">
              <h3 class="line-number">03</h3>
              <h4>求人支援</h4>
            </div>
            <p>・求人コンサルティング</p>
            <p>・自社求人サイト制作</p>
            <p>・求人媒体の展開とコンテンツ制作</p>
            <img src="@/assets/u_create_top_002.webp" alt="株式会社U-CREATEで名刺、チラシ、封筒を作成！">
          </div>
        </div>
        <div class="business-line last">
          <h3 class="line-number">04</h3>
          <div class="business-line-content">
            <div class="sp-title">
              <h3 class="line-number">04</h3>
              <h4>DTP・デザイン</h4>
            </div>
            <p>・名刺、封筒、チラシなどのデザイン制作</p>
            <p>・紙媒体の印刷</p>
            <p>・動画編集</p>
            <img src="@/assets/u_create_top_003.webp" alt="株式会社U-CREATEで集客強化を実現！">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BusinessLines",
}
</script>

<style lang="scss" scoped>
section {
  .flex-cover {
    padding: 150px 0;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      display: block;
      padding: 75px 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 75px 20px;
    }
    .left {
      width: 30%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h2 {
        color: var(--black);
        font-size: 70px;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          text-align: center;
        }
        @media screen and (max-width: 767px) {
          font-size: 60px;
          line-height: 3.5rem;
        }
      }
      p {
        color: var(--black);
        @media screen and (max-width: 1100px) {
          text-align: center;
        }
      }
    }
    .right {
      width: 65%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 85%;
        margin: auto;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-top: 20px;
      }
      .business-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
        .line-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12%;
          aspect-ratio: 1/1;
          font-size: 40px;
          color: var(--white);
          border-radius: 999px;
          background-color: var(--sub2);
          margin-bottom: auto;
          @media screen and (max-width: 1100px) {
            display: none;
          }
        }
        .business-line-content {
          width: 83%;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
          .sp-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .line-number {
              display: none;
              @media screen and (max-width: 1100px) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                aspect-ratio: 1/1;
                font-size: 30px;
                color: var(--white);
                border-radius: 999px;
                background-color: var(--sub2);
                margin-bottom: auto;
                @media screen and (min-width: 768px) and (max-width: 1100px) {
                  width: 15%;
                }
              }
            }
            h4 {
              width: 75%;
              font-size: 28px;
              margin: 0;
              line-height: 2.5rem;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                width: 80%;
                font-size: 30px;
              }
            }
          }
          h4 {
            font-size: 28px;
            margin-bottom: 10px;
          }
          p {
            line-height: 2rem;
            margin-bottom: 10px;
          }
          img {
            margin-top: 20px;
          }
        }
      }
      .last {
        margin: 0;
      }
    }
  }
}
</style>