<template>
  <div class="ui-table4">
    <div class="table-inner">
      <table>
        <tr v-for="table in tableList" :key="table.id">
          <th v-html="table.th"></th>
          <td v-html="table.td"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTable4",
  components: {},
  props: {
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  padding: 0 83px;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 2px var(--black) solid;
      padding: 10px 0;
    }
    th {
      text-align: center;
      width: 30%;
      background-color: rgb(226, 226, 226);
      vertical-align: middle;
    }
    td {
      text-align: left;
      width: 70%;
      padding: 10px 20px;
      @media screen and (max-width: 767px) {
        padding-left: 10px;
      }
    }
  }
  // tr:last-child{
  //   border-bottom: solid 1px var(--black);
  //    @media screen and (max-width: 767px){
  //      border: none;
  //    }
  // }
}
</style>
