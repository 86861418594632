<template>
  <div class="body-top">
    <Header />

    <div class="top">
      <HomeTop />
    </div>

    <div class="back-video">
      <video loop autoplay muted playsinline>
        <source src="/video/back.mp4" />
        <!-- <source src="/video/header1280-720px.mp4" /> -->
        このブラウザではサポートされていません。
      </video>

      <div>
        <TopBar class="bg-color1"/>
      </div>

      <div class="bg-color2">
        <HomeAboutUs />
      </div>

      <HomeBusinessLines />

      <HomeFreeConsultation />

      <HomeCreatorProfile />

      <HomeReasonsToChoose />

      <HomeLookingForPartners />

      <!-- <div class="top pb-200">
        <HomeCompanyInfo />
      </div>
      <div class="top pt-200 pb-200 back-01">
        <HomeGreeting />
      </div>
      <div class="top pt-200 pb-200">
        <HomeRecruiting />
      </div> -->

      <HomeContact />
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layout/Header.vue";
import HomeTop from "@/components/Home/Top.vue";
import TopBar from "@/components/Home/TopBar.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeBusinessLines from "@/components/Home/BusinessLines.vue";
import HomeFreeConsultation from "@/components/Home/FreeConsultation.vue";
import HomeCreatorProfile from "@/components/Home/CreatorProfile.vue";
import HomeReasonsToChoose from "@/components/Home/ReasonsToChoose.vue";
import HomeLookingForPartners from "@/components/Home/LookingForPartners.vue";
import Footer from "@/components/Layout/Footer.vue";

// import HomeCompanyInfo from "@/components/Home/CompanyInfo.vue";
// import HomeGreeting from "@/components/Home/Greeting.vue";
// import HomeRecruiting from "@/components/Home/Recruiting.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    Header,
    HomeTop,
    TopBar,
    HomeAboutUs,
    HomeBusinessLines,
    HomeFreeConsultation,
    HomeCreatorProfile,
    HomeReasonsToChoose,
    HomeLookingForPartners,
    // HomeCompanyInfo,
    // HomeGreeting,
    // HomeRecruiting,
    HomeContact,
    Footer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.back-video {
  video {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0.4;
  }
}
.bg-color1 {
  background-color: var(--main);
  color: white;
}
.bg-color2 {
  background-color: var(--sub2);
}
</style>
