<template>
  <section class="lp-space">
    <div class="wrap">
      <div class="flex-cover">
        <div class="introduction">
          <h2 class="title">代表者紹介</h2>
          <p>
            当サイトをご覧いただきありがとうございます。当サイトでは、私が自信を持って提供する求人強化の支援サービスをご紹介しています。これまで岐阜エリアで何社も支援させていただき、必ず応募数と採用数を増やすことに成功してきました。このノウハウ・施策に興味がありましたら、まずは無料相談にお申し込みをお願いいたします。どのように求人を改善していくのか、その方法についてもお伝えさせていただきます。無料相談でお会いできることを楽しみにしています。
          </p>
        </div>
        <div class="profile">
          <div class="creator-img">
            <img class="creator-icon" src="@/assets/u_create_creator_01.webp" alt="株式会社U-CREATE 代表取締役 酒井優">
            <div class="creator-name">
              <p class="company">株式会社U-CREATE</p>
              <p class="name">代表取締役 <span class="name"><br class="sp-only">酒井 優</span> Sakai Yu</p>
            </div>
          </div>
          <p class="creator-message">
            ウェブマーケティング×ウェブ制作が専門分野で、6年間で100社以上の企業を支援。これまで支援したクライアントの解約件数は0。好きなものはお酒、美容、中日ドラゴンズ。
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RepresentativeVue"
}
</script>

<style lang="scss" scoped>
  section {
    .wrap {
      .flex-cover {
        .introduction {
          width: 50%;
          margin-bottom: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 15px;
          }
          .title {
            font-size: 32px;
            letter-spacing: 0.3rem;
            margin-top: 0;
            margin-bottom: 30px;
            @media screen and (max-width: 767px) {
              font-size: 26px;
            }
          }
          p {
            @media screen and (max-width: 767px) {
              line-height: 2rem;
            }
          }
        }
        .profile {
          width: 42%;
          margin-bottom: auto;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 48%;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
          }
          .creator-img {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            @media screen and (max-width: 767px) {
              margin-bottom: 20px;
            }
            .creator-icon {
              width: 40%;
              aspect-ratio: 1/1;
              border-radius: 20px;
              @media screen and (max-width: 1100px) {
                width: 35%;
              }
            }
            .creator-name {
              display: flex;
              align-content: flex-end;
              flex-wrap: wrap;
              width: 55%;
              font-size: 16px;
              line-height: 1.5rem;
              margin: 0;
              @media screen and (min-width: 768px) and (max-width: 1100px) {
                width: 58%;
                font-size: 24px;
                line-height: 2rem;
                margin-left: 20px;
              }
              @media screen and (max-width: 767px) {
                font-size: 16px;
                margin-left: 15px;
              }
              .company {
                width: 100%;
                font-size: 18px;
                margin-bottom: 5px;
              }
              .name {
                font-size: 16px;
                span {
                  font-size: 22px;
                  margin: 0 0.5rem;
                }
              }
            }
          }
          .creator-message {
            color: var(--sub2);
            font-size: 16px;
            line-height: 1.7rem;
            margin: 0;
          }
        }
      }
    }
  }
</style>