<template>
  <section>
    <div class="wrap">
      <h2>Reasons to Choose<br>U-CREATE</h2>
      <p>ユークリエイトが選ばれる理由</p>
      <div class="reason-content">
        <div class="reason">
          <h3>マーケターだからできる、<br>クライアントの目的を達成する<br>制作物をご提案</h3>
          <p>
            株式会社U-CREATEはただ制作物を作るだけではありません。ターゲット心理・競合コンテンツ・サジェストキーワードなどの調査と解析、クライアントへのヒアリングに基づいたWebマーケティングによって、制作物を通して目的を達成します。
          </p>
        </div>
        <img src="@/assets/u_create_top_005.webp" alt="目的に合った制作物をご提案" class="reason-img">
      </div>
      <div class="reason-content reverse">
        <img src="@/assets/u_create_top_006.webp" alt="目的に合った制作物をご提案" class="reason-img">
        <div class="reason">
          <h3>紙媒体からプログラムまで<br>ワンストップでの対応が可能</h3>
          <p>
            名刺やチラシなどの紙媒体のデザインと印刷から、SNSへの投稿用画像、ホームページ、ECサイト、業務システムまで対応可能です。そのため、以下のメリットを提供できます。
             <br>
            ・フルパッケージでの支援
             <br>
            ・事業の成長時に業者の切り替え不要
             <br>
            ・制作物のデータの流用によるコスト低減
             <br>
          </p>
        </div>
      </div>
      <div class="reason-content">
        <div class="reason">
          <h3>柔軟なコスト調整</h3>
          <p>
            「とにかくコストを下げたい」という方には、柔軟にコスト調整するプランをご用意しています。「本業に集中したいからすべてお任せしたい」という方に向けた、全面的に支援するプランもご用意しています。
          </p>
        </div>
        <img src="@/assets/u_create_top_007.webp" alt="目的に合った制作物をご提案" class="reason-img">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReasonsToChoose"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  padding: 150px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 75px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 75px 0;
  }
  .wrap {
    h2 {
      color: var(--sub2);
      font-size: 70px;
      font-weight: 700;
      line-height: 4.5rem;
      margin-bottom: 10px;
      @media screen and (max-width: 767px) {
        font-size: 55px;
        line-height: 3.5rem;
      }
    }
    p {
      color: var(--sub2);
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
    .reason-content {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap-reverse;
        margin-top: 40px;
      }
      .reason {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        h3 {
          font-size: 28px;
          color: var(--sub2);
          line-height: 2.5rem;
          @media screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 2rem;
            margin: 20px 0 10px 0;
          }
        }
        p {
          color: var(--white);
          @media screen and (max-width: 767px) {
            text-align: start;
            line-height: 2rem;
          }
        }
      }
      .reason-img {
        width: 48%;
        margin-bottom: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .reverse {
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
  }
}
</style>