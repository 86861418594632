<template>
  <div class="top">
    <div class="top-video">
      <video ref="player" autoplay muted playsinline loop>
        <source src="/video/Top.mp4" />
        このブラウザではサポートされていません。
      </video>
      <div class="top-message">
        <p>経営者様の<br class="sp-only">「これやりたい」を<br>お手伝いします。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  // mounted: function () {
  //   let player = this.$refs.player;
  //   let container = this.$refs.container;

  //   player.addEventListener("timeupdate", function () {
  //     if (player.currentTime > 23) {
  //       container.style.display = "none";
  //       container.classList.remove("animation-start");
  //     }
  //   });

  //   player.addEventListener("ended", function () {
  //     console.log("ended");
  //     container.style.display = "";
  //     container.classList.add("animation-start");
  //     player.play();
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.top {
  .top-video {
    position: relative;
    width: 100vw;
    height: 80vh;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: auto;
    }
    @media screen and (max-width: 767px) {
      height: 80vh;
      width: 300%;
      overflow: hidden;
    }
    video {
      position: absolute;
      top: -10vh;
      left: 0;
      width: 100%;
      height: 90vh;
      z-index: 0;
      object-fit: cover;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        display: block;
        position: static;
        width: 100vw;
        height: auto;
      }
      @media screen and (max-width: 767px) {
        overflow: visible;
        height: 90vh;
        left: 0;
      }
    }
    .top-message {
      position: absolute;
      width: 45%;
      top: 20%;
      right: 1%;
      min-width: 55%;
      @media screen and (min-width: 1025px) and (max-width: 1400px) {
        width: 52%;
        top: 20%;
        right: 20px;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 65%;
        top: 15%;
        right: 1%;
      }
      @media screen and ( max-width: 767px) {
        width: 90%;
        top: 5%;
        left: 40px;
      }
      p {
        color: var(--main);
        font-size: 45px;
        letter-spacing: 5px;
        line-height: 60px;
        @media screen and (min-width: 768px) and (max-width: 1400px) {
          font-size: 35px;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-size: 30px;
          letter-spacing: 5px;
        }
        @media screen and ( max-width: 767px) {
          writing-mode: vertical-rl;
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
