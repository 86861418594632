<template>
  <div class="home-about-us">
    <div class="flex-cover wrap">
      <div class="left">
        <h2>About<br>U-CREATE</h2>
        <h1>ユークリエイトについて</h1>
      </div>
      <div class="right">
        <p>
          株式会社U-CREATEは、経営者様の「これをやりたい」というご要望に応え、Webマーケティングを通じて、Webサイト・LP・ECサイトおよびモールの制作、システム開発などを行い、目的達成や課題解決のお手伝いをしております。私たちは、集客、求人、業務効率化に強みを持ち、「マーケティング」「コード・プログラム」「デザイン」の3つの分野において高いスキルを駆使し、Web・ITのパートナーとして貴社の事業を全力でサポートいたします。初回のご相談は無料ですので、どうぞお気軽にお問い合わせください。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "home-top-class-website",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.flex-cover {
  padding: 150px 0;
  align-items: center;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    padding: 70px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 60px 20px;
  }
  .left {
    width: 30%;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 38%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    h2 {
      color: var(--black);
      font-size: 70px;
      font-weight: 900;
      line-height: 4.5rem;
      margin-bottom: 10px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        font-size: 55px;
      }
      @media screen and (max-width: 767px) {
        font-size: 55px;
        line-height: 3.5rem;
      }
    }
  }
  .right {
    width: 65%;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 60%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 20px;
    }
  }
}
</style>
