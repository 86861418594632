// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto/vue-scrollto.js'
import VueHead from 'vue-head'
import VueCarousel from 'vue-carousel'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VuePrlx from 'vue-prlx'

Vue.use(VueHead)

Vue.use(VueScrollTo, {
  offset: -70
})

Vue.use(VueCarousel);

Vue.use(VueAwesomeSwiper)

Vue.use(VuePrlx);

//グローバル登録
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')