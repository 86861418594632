<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline': (scrollY >= 600),
      }"
    >
      <div class="to-top" @click="top">
        <img src="@/assets/logo001.webp" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    // UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
          }.bind(this),
          200
        );
      }
    },
    top() {
      this.$scrollTo("#app", 500, { easing: "ease" });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 1s;
  display: flex;
  align-items: center;
  padding: 0 3%;
  @media screen and ( max-width: 767px){
    height: 60px;
    padding: 0 5%;
  }
  .to-top {
    width: 300px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s;
    @media screen and ( max-width: 767px){
      width: 200px;
    }
  }
  .to-top:hover {
    opacity: 0.9;
  }
}
.over-baseline {
  display: initial !important;
  display: flex !important;
  transition: 2s;
  background-color: rgba(255, 255, 255, 0.5);

  .header-cover {
    border: none;
  }
}
</style>
