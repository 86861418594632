<template>
  <div id="footer" class="footer">
    <!-- topページ -->
    <div
      v-if="showFooterScrollButton"
      v-show="this.$route.path === '/closed/recruit/'"
      class="sp-contact"
    >
      <div class="scroll-button" v-scroll-to="'#contact'">
        初回無料相談に申し込む
      </div>
    </div>

    <div class="wrap">
      <img
        class="footer-img"
        src="@/assets/logo002.webp"
        alt="グレイトヘルプロゴ"
      />
      <p class="company">株式会社 U-CREATE</p>
      <p class="description">
        500-8468 岐阜県岐阜市加納桜田町 2-5-2
        <a class="hp-link tb-pc-only" href="https://u-create21.com" target="_blank">https://u-create21.com</a>
      </p>
      <a class="hp-link sp-only" href="https://u-create21.com" target="_blank">https://u-create21.com</a>
    </div>
    <p class="copyright">Copyright © U-CREATE All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "LpFooter",
  data() {
    return {
      showFooterScrollButton: false,
    };
  },
  mounted() {
    // スクロールイベントを監視
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showFooterScrollButton = scrollTop > 200;

      // 特定の要素までスクロールしたかどうかチェック
      const specialElement = document.getElementById("contact"); // 特定の要素のIDを指定する
      if (specialElement) {
        const bounding = specialElement.getBoundingClientRect();
        // 特定の要素の上端が画面内に入った場合、ボタンを非表示
        if (bounding.top >= window.innerHeight) {
          // no action
        } else {
          this.showFooterScrollButton = false;
        }
      } else {
        // no action
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .sp-contact {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 60px;
    z-index: 5;
    display: none;
    .scroll-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100%;
      padding: 2% 0;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.2rem;
      color: var(--white);
      background-color: var(--sub2);
      border: 1px solid var(--white);
      &:hover {
        background-color: var(--sub2);
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
    .scroll-img {
      width: auto;
      height: 100%;
    }
  }
  .footer {
    background-color: var(--main);
    padding-top: 150px;
    padding-bottom: 30px;
    @media screen and (max-width: 767px) {
      padding-top: 100px;
    }
    .wrap {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          margin-bottom: 10px;
        }
        @media screen and (max-width: 767px) {
          padding-right: 30px;
          margin-bottom: 40px;
        }
      .footer-img {
        width: 330px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          width: 250px;
          margin: 0 auto 20px auto;
        }
      }
      p {
        color: var(--white);
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        letter-spacing: 0.2rem;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0rem;
          text-align: center;
        }
      }
      .company {
        margin-bottom: 3px;
        @media screen and (max-width: 767px) {
          margin-bottom: 5px;
        }
      }
      .hp-link {
        color: var(--white);
        text-decoration: none;
        margin-left: 2rem;
        @media screen and (max-width: 767px) {
          margin-left: 0;
          font-size: 16px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
    .copyright {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: end;
      margin-right: 5%;
      color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        margin-right: 6%;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
  }
</style>