<template>
  <section>
    <div class="wrap">
      <div class="message">
        <h2 class="message-title tb-pc-title">
          初回無料相談で具体的な
          <br class="sp-only">
          ノウハウを教えします
        </h2>
        <img class="point-img tb-pc-only" src="@/assets/lp/ok.webp" alt="OK!">
        <img class="point-img sp-only" src="@/assets/lp/t004sp.webp" alt="OK!">
        <p class="message-text">
          貴社のお悩みをお聞きした上で、初回無料相談では、当社の求人ノウハウについてご説明させていただきます。どのような施策を実行して、どのような求人の強化をしていくのかを理解することができます。求人を強化したい事業主様はぜひ初回無料相談にお申し込みをください。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PointMessage"
}
</script>

<style lang="scss" scoped>
  section {
    background-color: var(--main);
    .wrap {
      padding: 60px 0;
      .message {
        position: relative;
        width: 90%;
        @media screen and (min-width: 1100px) and (max-width: 1470px) {
          width: 75%;
          padding-left: 20px;
        }
        @media screen and (max-width: 1100px) {
          width: 100%;
          padding: 0 20px;
        }
        .message-title {
          font-size: 36px;
          font-size: bold;
          letter-spacing: 0.3rem;
          margin-top: 0;
          margin-bottom: 20px;
          color: var(--sub2);
          @media screen and (max-width: 1100px) {
            text-align: center;
            font-size: 25px;
            margin-bottom: 0;
          }
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        p {
          color: var(--white);
        }
        .point-img {
          position: absolute;
          top: 0;
          right: -280px;
          width: 250px;
          @media screen and (min-width: 1100px) and (max-width: 1470px) {
            width: 220px;
            top: 0;
            right: -240px;
          }
          @media screen and (max-width: 1100px) {
            position: static;
            width: 50%;
            margin: 0 auto;
          }
          @media screen and (max-width: 767px) {
            position: static;
            width: 100%;
            margin-bottom: 20px;
          }
        }
        .message-text {
          @media screen and (max-width: 767px) {
            line-height: 2rem;
          }
        }
      }
    }
  }
</style>