<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
        <div class="logo">
          <img
            alt="グレイトヘルプロゴ"
            src="@/assets/logo002.webp"
          />
        </div>
        <div class="copyright">
          <p>Copyright © U-CREATE All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--sub2);
}
.st1 {
  fill: none;
}
.tokuteisho {
  text-align: center;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.footer {
  .fotter-inner {
    background-color: var(--main);
    // height: 150px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 350px;
        margin: 80px 0 20px 0;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin: 50px 0 20px;
          width: 350px;
        }
        @media screen and (max-width: 767px) {
          width: 250px;
          margin: 70px 0 0 0;
        }
      }
    }
  }

  .copyright {
    font-size: 13px;
    color: white;
    text-align: right;
    padding-bottom: 30px;
    margin-right: 100px;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
