<template>
  <section class="lp-space">
    <div class="wrap">
      <div class="flex-cover">
        <div class="title">
          <img src="@/assets/lp/t001.webp" alt="CHECK！" class="title-img tb-pc-only">
          <img src="@/assets/lp/t001sp.webp" alt="CHECK！" class="title-img sp-only">
        </div>
        <div class="issues">
          <div v-for="(issue, index) in issues" :key="issue">
            <div class="issue" :class="{ 'last-item': index === issues.length - 1 }">
              <img src="@/assets/lp/check.webp" alt="チェック" class="check-img">
              <p class="issue-text" v-html="issue"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="proposal">
        <p class="proposal-text">
          もし、あなたがこんな悩みを抱えているのでしたら、ぜひ引き続きこのサイトをお読みください。これまでの支援実績で、応募数・採用数の増加の成功率100%の求人支援サービスの案内です。初回無料相談を承っていますので、興味がありましたらぜひお申し込みください。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RecruitIssue",
  data() {
    return {
      issues: [
        "人材を採用したいが、応募がない、応募が少ない",
        "人材を採用したいが、何をすればいいかわからない",
        "自社でやれることをやっているが、<br class='pc-only'>応募や採用につながってない",
        "求人や採用について、相談できる人がいない",
        "有料求人媒体に出稿しているが、料金が高くて困っている",
        "有料求人媒体はお金が高くて継続して出稿できない",
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-cover {
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      line-height: 3rem;
      letter-spacing: 0.3rem;
    }
    .title {
      width: 35%;
      margin-bottom: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 15px;
      }
      .title-img {
        width: 80%;
        @media screen and (max-width: 1100px) {
          width: 100%;
        }
      }
    }
    .issues {
      width: 55%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .issue {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .check-img {
          width: 50px;
          height: 45px;
          margin-right: 20px;
        }
        .issue-text {
          font-size: 20px;
          line-height: 2rem;
          letter-spacing: 0.3rem;
          @media screen and (max-width: 767px) {
            letter-spacing: 0;
          }
        }
      }
      .last-item {
        margin-bottom: 0;
      }
    }
  }
  .proposal {
    padding: 60px 100px;
    border-radius: 60px;
    margin-top: 40px;
    background-color: var(--sub2);
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      padding: 20px;
      border-radius: 20px;
    }
    .proposal-text {
      color: var(--white);
      font-size: 18px;
      @media screen and (max-width: 767px) {
        line-height: 30px;
      }
    }
  }
</style>