<template>
  <section>
    <div class="flex-cover wrap">
      <div class="left">
        <h3>初回無料相談&コンサルティング<br class="tb-only">を実施しています</h3>

        <div class="video-content tb-sp-only">
          <video loop autoplay muted playsinline>
            <source src="/video/maru1.mp4" />
            このブラウザではサポートされていません。
          </video>
        </div>

        <p>
          Web集客したい、ホームページを新しくしたい、
          <br class="pc-only">
          求人強化したいなどのWebマーケティングに関するお悩み事を初回無料でご相談いただけます。
        </p>
      </div>

      <div class="video-content pc-only">
        <video loop autoplay muted playsinline>
          <source src="/video/maru1.mp4" />
          このブラウザではサポートされていません。
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FreeConsultation",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 80px 0;
  }
  .wrap {
    .left {
      width: 80%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h3 {
        color: var(--sub2);
        @media screen and (max-width: 1100px) {
          text-align: center;
        }
      }
      p {
        line-height: 2rem;
        color: var(--white);
      }
    }
    .video-content {
      width: 20%;
      position: relative;
      @media screen and (max-width: 1100px) {
        width: 100%;
        margin: 20px 0;
      }
      video {
        position:absolute;
        top: -25px;
        right: -10%;
        width: 140%;
        clip-path:circle(32% at 50% 50%);
        @media screen and (max-width: 1100px) {
          display: block;
          position: static;
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}
</style>