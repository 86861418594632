<template>
  <div class="home-top-bar">
    <div class="flex-cover-1 box-cover">
      <div class="box sp-top">
        <!-- <img class="first" alt="ホームページ制作" src="@/assets/u_create_komoku001.webp" /> -->
        <p class="first bar-text">Webマーケティング</p>
      </div>
      <div class="box sp-top sp-last">
        <!-- <img class="second" alt="求人強化" src="@/assets/u_create_komoku002.webp" /> -->
        <p class="second bar-text">WEB制作<span class="pc-only tablet-only">・</span><br class="sp-only"/>システム開発</p>
      </div>
      <div class="box sp-under">
        <!-- <img class="third" alt="Web集客" src="@/assets/u_create_komoku003.webp" /> -->
        <p class="third bar-text">求人支援</p>
      </div>
      <div class="box sp-under last">
        <!-- <img class="last" alt="名刺・資料" src="@/assets/u_create_komoku004.webp" /> -->
        <p class="last bar-text">DTP・デザイン</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "home-top-class-website",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box-cover {
  height: 12vh;
  align-items: center;
  @media screen and (max-width: 1100px) {
    height: auto;
    padding: 20px 0;
  }
}
.flex-cover-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px 10px;
  }
  .box {
    width: 25%;
    border-right: 2px solid var(--white);
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 60px;
    }
    .bar-text {
      width: auto;
      text-align: center;
      font-size: 25px;
      line-height: 30px;
      margin: auto;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 16px;
        // width: 85%;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        width: 85%;
      }
    }
      @media screen and (max-width: 767px) {
        .first, .second, .third, .last {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
        }
      }
  }
  .sp-top {
    @media screen and (max-width: 767px) {
      // padding-bottom: 10px;
      border-top: 2px solid var(--main);
      border-bottom: 2px solid var(--white);
    }
  }
  .sp-under {
    @media screen and (max-width: 767px) {
      // padding-top: 10px;
    }
  }
  .sp-last {
    @media screen and (max-width: 767px) {
      border-right: none;
    }
  }
  .last {
    border: none;
  }
}
</style>
