<template>
  <section>
    <div class="wrap">
      <div class="title">
        <h2>Looking For<br>Partners</h2>
        <p>パートナー募集</p>
      </div>

      <div class="video-content tb-sp-only">
        <video loop autoplay muted playsinline>
          <source src="/video/maru2.mp4" />
          このブラウザではサポートされていません。
        </video>
      </div>

      <p class="content">
        株式会社U-CREATEでは、お仕事において「紹介」「協業」などのパートナーとして活動する法人・個人事業主を探しています。Web・ITにおいて幅広い業務に対応できるため、株式会社U-CREATEとパートナーとなることで、獲得できるお仕事、実現できるお仕事を増やすことができます。興味がある方はぜひお気軽にお問い合わせください。
      </p>

      <div class="video-content pc-only">
        <video loop autoplay muted playsinline>
          <source src="/video/maru2.mp4" />
          このブラウザではサポートされていません。
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LookingForPartners",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 75px 0;
  }
  .wrap {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1100px) {
      display: block;
    }
    .title {
      width: 30%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h2 {
        color: var(--black);
        font-size: 53px;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          text-align: center;
        }
        @media screen and (max-width: 767px) {
          font-size: 60px;
          line-height: 3.5rem;
        }
      }
      p {
        color: var(--black);
        @media screen and (max-width: 1100px) {
          text-align: center;
        }
      }
    }
    .content {
      width: 50%;
      line-height: 2rem;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
    .video-content {
      width: 20%;
      position: relative;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 80%;
        margin: auto;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      video {
        position:absolute;
        top: -90px;
        right: -15%;
        width: 130%;
        clip-path:circle(32% at 50% 50%);
        @media screen and (max-width: 1100px) {
          display: block;
          position: static;
          width: 100%;
          margin: 20px auto;
        }
      }
    }
  }
}
</style>